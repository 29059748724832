import _isEqual from 'lodash/isEqual'
import { cloneValue } from '../libs/object'

export default {
  computed: {
    /**
     * Classes for input component
     */
    inputClasses() {
      let classes = `i--${this.type}`
      if (this.error) {
        classes += ' i--error'
      }
      if (this.readonly) {
        classes += ' i--disabled'
      }
      return classes
    },
  },
  data() {
    return {
      /**
       * Init input value by cloning prop value
       */
      inputValue: cloneValue(this.value),
    }
  },
  props: {
    /**
     * Input validation error
     */
    error: {
      default: '',
      type: String,
    },

    /**
     * Input element name
     */
    name: {
      required: false,
      type: String,
    },

    /**
     * Input placeholder value
     */
    placeholder: {
      default: '',
      type: String,
    },

    /**
     * Whether input element is read-only
     */
    readonly: {
      default: false,
      type: Boolean,
    },

    /**
     * Input label title
     */
    title: {
      default: '',
      type: String,
    },
  },
  watch: {
    /**
     * Emit input value change event
     * if new value different from prop value
     */
    inputValue: {
      deep: true,
      handler(newValue) {
        if (!_isEqual(newValue, this.value)) {
          this.$emit('input', cloneValue(newValue))
        }
      },
    },

    /**
     * Update input value
     * if prop value updated
     */
    value(newValue) {
      if (!_isEqual(newValue, this.inputValue)) {
        this.inputValue = cloneValue(newValue)
      }
    },
  },
}
