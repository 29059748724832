<template>
  <form class="popup form" novalidate :class="formClass" @submit.prevent>
    <div v-if="showBanner" class="popup__banner">
      <slot name="banner" />
    </div>
    <div class="popup__main" :class="fixedHeightClass">
      <popup-header
        v-if="showMasthead || showClose || showBack || backPath"
        :back-path="backPath"
        :back-query="backQuery"
        :show-back="showBack"
        :show-close="showClose"
        :action-title="actionTitle"
        :show-masthead="showMasthead"
      />
      <section class="popup__content">
        <slot name="main" />
      </section>
      <section v-if="showActions" class="popup__actions">
        <slot name="actions" />
      </section>
    </div>
    <slot name="overlay" />
  </form>
</template>

<script>
import PopupHeader from './popupHeader'

export default {
  components: {
    'popup-header': PopupHeader,
  },
  computed: {
    fixedHeightClass() {
      return this.fixedHeight ? 'popup__main--fixed-height' : ''
    },
    formClass() {
      if (this.small) {
        return 'popup--sm'
      }

      if (this.large) {
        return 'popup--l'
      }
      return ''
    },
    showActions() {
      // Only show popup actions when slot content available
      return !!this.$slots.actions
    },
    showBanner() {
      // Only show popup banner when slot content available
      return !!this.$slots.banner
    },
  },
  props: {
    actionTitle: {
      default: '',
      type: String,
    },
    /**
     * Route path for back link
     */
    backPath: {
      default: '',
      type: String,
    },

    /**
     * Route query for back link
     */
    backQuery: {
      default: () => {},
      type: Object,
    },
    fixedHeight: {
      default: true,
      type: Boolean,
    },
    /**
     * Whether to use large layout
     */
    large: {
      default: false,
      type: Boolean,
    },

    /**
     * Whether to show back button
     */
    showBack: {
      default: false,
      type: Boolean,
    },

    /**
     * Whether to show close button
     */
    showClose: {
      default: false,
      type: Boolean,
    },

    /**
     * Whether to show masthead
     */
    showMasthead: {
      default: false,
      type: Boolean,
    },

    /**
     * Whether to use small layout
     */
    small: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style lang="postcss" scoped>
.popup {
  @apply flex w-full h-full;
}

/* Popup main content */
.popup__main {
  @apply flex-1 flex flex-col;
  @apply relative w-full;
  overflow-x: clip;

  & > * {
    @apply relative px-4 z-0;
  }
}

.popup__main--fixed-height {
  @apply min-h-0 overflow-x-hidden;
}
.popup-hdr {
  @apply pt-2 pb-0;
}
.popup__content {
  @apply flex w-full pt-2 pb-6;
  @apply min-h-0;
  @apply overflow-y-auto;
}

.popup__actions {
  @apply w-full pt-6 pb-6 z-1;
}

/* Popup banner */
.popup__banner {
  @apply relative w-full;
}

@screen mobile {
  .popup {
    @apply flex-col max-w-form items-center;
  }
}

@screen desktop {
  .popup {
    @apply justify-center relative;
    width: 800px;
    max-height: 640px;
  }
  .popup--sm {
    width: 400px;
    max-height: 496px;
  }
  .popup--l {
    width: 496px;
    max-height: 880px;
  }

  /* Popup main content */
  .popup__main {
    @apply rounded-xl bg-white shadow overflow-y-auto;
  }
  .popup__main > * {
    @apply px-8;
  }
  .popup-hdr {
    @apply pt-4 pb-0;
  }
  .popup__content {
    @apply flex-grow py-4 pt-4 justify-center;
  }
  .popup__actions {
    @apply sticky bottom-0 -mt-4 pt-4 pb-10;
    @apply rounded-b-xl bg-white;
  }

  /* Popup banner */
  .popup__banner {
    @apply flex-1 h-full rounded-l-xl overflow-hidden;
  }
}
</style>
