<template>
  <div>
    <div ref="turnstile" class="cf-turnstile"></div>
  </div>
</template>

<script>
export default {
  beforeDestroy() {
    this.destroyTurnstile()
  },
  data() {
    return {
      turnstileWidget: null,
    }
  },
  methods: {
    destroyTurnstile() {
      if (this.turnstileWidget) {
        window.turnstile.reset(this.$refs.turnstile)
        this.turnstileWidget = null
      }
    },
    loadTurnstileScript() {
      if (!window.turnstile) {
        const script = document.createElement('script')
        script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit'
        script.async = true
        script.defer = true
        script.onload = this.renderTurnstile
        document.head.appendChild(script)
      } else {
        this.renderTurnstile()
      }
    },
    onSuccess(token) {
      this.$emit('success', token)
    },
    renderTurnstile() {
      this.$nextTick(() => {
        if (this.$refs.turnstile) {
          // Mapper to Cloudflare languages
          // https://developers.cloudflare.com/turnstile/reference/supported-languages/
          const langMapper = {
            'zh-hans': 'zh',
            'zh-hant': 'zh-tw',
          }
          this.turnstileWidget = window.turnstile.render(this.$refs.turnstile, {
            callback: this.onSuccess,
            language: langMapper[this.language] || this.language,
            sitekey: this.siteKey,
            theme: 'light',
          })
        }
      })
    },
  },
  mounted() {
    this.loadTurnstileScript()
  },
  props: {
    language: {
      default: 'en',
      type: String,
    },
    siteKey: {
      required: true,
      type: String,
    },
  },
}
</script>
