<template>
  <nuxt-link to="/">
    <span class="masthead">
      <svg-moovup-logo class="masthead__logo" />
    </span>
  </nuxt-link>
</template>

<script>
import SvgMoovupLogo from '../assets/svg/moovupLogo_v4_red.svg?inline'

export default {
  components: {
    'svg-moovup-logo': SvgMoovupLogo,
  },
}
</script>

<style lang="postcss" scoped>
.masthead {
  @apply flex items-center w-full justify-center;
}
.masthead__logo {
  width: 72%;
  padding-top: 1.1rem;
  padding-bottom: 0.9rem;
}
</style>
