<template>
  <section class="popup-hdr">
    <div class="popup-hdr__link">
      <bk-link v-show="showBack || backPath" :path="backPath" :query="backQuery" @click.native="onBack" />
      <b-icon v-show="showClose" :title="$t('general.close')" @click="onClose">
        <svg-close slot="icon" class="popup-hdr__icon svg-fill" />
      </b-icon>
    </div>
    <div class="popup-hdr__title">
      <masthead v-if="showMasthead" />
    </div>

    <op-but v-if="actionTitle" :title="actionTitle" @click="onAction" />
    <div v-if="!actionTitle" class="popup-hdr__link" />
  </section>
</template>

<script>
import BackLink from '../link/backLink'
import IconButton from '../button/iconButton'
import Masthead from '../masthead'
import OptionalButton from '../button/optionalButton'
import SvgClose from '../../assets/svg/close.svg?inline'

export default {
  components: {
    'b-icon': IconButton,
    'bk-link': BackLink,
    masthead: Masthead,
    'op-but': OptionalButton,
    'svg-close': SvgClose,
  },
  methods: {
    onAction() {
      if (this.$parent) {
        this.$parent.$emit('action')
      }
    },
    /**
     * Emit back event through parent (popup)
     * when back button clicked
     */
    onBack() {
      if (this.$parent) {
        this.$parent.$emit('back')
      }
    },

    /**
     * Emit close event through parent (popup)
     * when close button clicked
     */
    onClose() {
      if (this.$parent) {
        this.$parent.$emit('close')
      }
    },
  },
  props: {
    actionTitle: {
      default: '',
      type: String,
    },

    /**
     * Route path for back link
     */
    backPath: {
      default: '',
      type: String,
    },

    /**
     * Route query for back link
     */
    backQuery: {
      default: () => {},
      type: Object,
    },

    /**
     * Whether to show back button
     */
    showBack: {
      default: false,
      type: Boolean,
    },

    /**
     * Whether to show close button
     */
    showClose: {
      default: false,
      type: Boolean,
    },

    /**
     * Whether to show masthead
     */
    showMasthead: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style lang="postcss" scoped>
.popup-hdr {
  @apply flex items-center -mx-3;
  width: calc(100% + 1.5rem);
}
.popup-hdr__link {
  width: 40px;
  height: 40px;
}
.popup-hdr__title {
  @apply flex-grow flex justify-center items-center;
}

.popup-hdr__icon {
  @apply text-grey-900;
}

>>> .b__title {
  @apply text-red-500 text-cplain-action1 font-cplain-action1;
}

@screen desktop {
  .popup-hdr {
    @apply -mx-5;
    width: calc(100% + 2.5rem);
  }
}
</style>
