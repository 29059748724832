<template>
  <main class="login" :style="loginStyles">
    <f-login />
  </main>
</template>

<script>
import LoginFrom from '../../components/form/loginForm'

export default {
  components: {
    'f-login': LoginFrom,
  },
  computed: {
    loginStyles() {
      return this.$store.getters['device/isMobile']
        ? {
            background: '#ffffff',
          }
        : {
            background: `url('${process.env.SEEKER_WEB__S3_PREFIX}/img/wall_pattern.svg') center/500px`,
          }
    },
  },
  head() {
    return {
      title: this.$t('login.title'),
    }
  },
  layout: 'single',
  name: 'LoginPage',
}
</script>
