<template>
  <div class="i" :class="inputClasses">
    <label v-if="title" class="i__label" :for="`i-${name}`"
      ><span v-if="required" class="i__required">*</span>{{ title }}</label
    >
    <div class="i__container">
      <div v-if="options.length" class="i__dropdwon">
        {{ options[0] }}
      </div>
      <input
        :id="`i-${name}`"
        v-model.trim="inputValue"
        class="i__input"
        tabindex="0"
        :name="name"
        :placeholder="placeholder"
        :readonly="readonly"
        :type="type"
      />
    </div>
    <p v-show="error" class="i__msg">* {{ error }}</p>
  </div>
</template>

<script>
import InputMixin from '../../mixins/input'

export default {
  mixins: [InputMixin],
  props: {
    /**
     * Option list
     */
    options: {
      default: () => [],
      type: Array,
    },
    /**
     * required mark (*)
     */
    required: {
      default: false,
      type: Boolean,
    },
    /**
     * Input element type
     */
    type: {
      default: 'text',
      type: String,
    },

    /**
     * Default input value
     */
    value: {
      default: '',
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.i__required {
  @apply text-red-500;
}
</style>
