import _isString from 'lodash/isString'
import errorKeys from './errorKeys'

/**
 * Check if the input is checked
 * @param {boolean} value
 */
export function isChecked(checked) {
  if (checked !== true) {
    return errorKeys.CHECK_REQUIRED
  }
  return ''
}

/**
 * Returns function to validate file
 */
export function isFileValid({ sizeLimit = 10485760, types = [] } = {}) {
  /**
   * Check if file is valid
   * @param {File} file
   */
  const validator = (file) => {
    if (!(file instanceof File)) {
      return errorKeys.FILE_NOT_SELECTED
    } else if (file.size > sizeLimit) {
      return errorKeys.FILE_SIZE_EXCEED_LIMIT
    } else if (!types.includes(file.type)) {
      return errorKeys.FILE_TYPE_NOT_ACCEPTED
    }
    return ''
  }

  return validator
}

/**
 * Check if string is email,
 * email regexp ref: https://stackoverflow.com/a/46181
 * @param {string} text
 */
export function isTextEmail(text) {
  const emailRegexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!emailRegexp.test(text)) {
    return errorKeys.EMAIL_INVALID
  }
  return ''
}

/**
 * Check if string is non empty
 * @param {string} text
 */
export function isTextNonEmpty(text) {
  if (!text) {
    return errorKeys.REQUIRED
  }
  return ''
}

/**
 * Check if string is password,
 * i.e. 6 or more alphanumeric characters
 * @param {string} text
 */
export function isTextPassword(text) {
  if (!/^([A-Za-z0-9]){6,}$/.test(text)) {
    return errorKeys.PASSWORD_INVALID
  }
  return ''
}

/**
 * Check if string is telephone number,
 * i.e. 8 or more numbers
 * @param {string} text
 */
export function isTextTelephoneNo(text) {
  if (!/^([456789][0-9]{7})$/.test(text)) {
    return errorKeys.TELEPHONE_INVALID
  }
  return ''
}

/**
 * Check if string is pin code,
 * i.e. 6 numbers
 * @param {string} text
 */
export function isTextPinCode(text) {
  if (text && !/^([0-9]){6}$/.test(text)) {
    return errorKeys.LOGIN_OTP_INVALID
  }
  return ''
}

/**
 * Returns function to validate list item count
 */
export function getListItemCountValidator({ fields = [], max = NaN, min = 1 } = {}) {
  /**
   * Field must be finite number or non-empty string
   */
  const isFieldValid = (field) => Number.isFinite(field) || (_isString(field) && field !== '')

  /**
   * Returns reduce function for checking item field valid
   */
  const getItemValidReducer = (item) => {
    return (itemInvalid, fieldName) => itemInvalid || !item || !isFieldValid(item[fieldName])
  }

  /**
   * Check if list item count is valid
   * @param {Array} list
   */
  const validator = (list) => {
    if (!Array.isArray(list)) {
      return errorKeys.LIST_INVALID
    }

    if (Number.isFinite(min)) {
      // List must not have fewer than minimum number of items
      if (list.length < min) {
        if (min === 1) {
          // Return special error key when minimum count is *1*
          return errorKeys.LIST_COUNT_BELOW_ONE
        }
        return errorKeys.LIST_COUNT_BELOW_MIN
      }

      // The minimum number of items must have required fields
      if (fields.length) {
        let itemsInvalid = false
        for (let i = 0; i < min; i++) {
          itemsInvalid = itemsInvalid || fields.reduce(getItemValidReducer(list[i]), false)
        }
        if (itemsInvalid) {
          return errorKeys.REQUIRED
        }
      }
    }

    if (Number.isFinite(max) && list.length > max) {
      // List must not have more than maximum number of items
      return errorKeys.LIST_COUNT_EXCEED_MAX
    }
    return ''
  }

  return validator
}

/**
 * Returns function to validate file
 */
export function getFileValidValidator({ sizeLimit = 10485760, types = [] } = {}) {
  /**
   * Check if file is valid
   * @param {File} file
   */
  const validator = (file) => {
    if (!(file instanceof File)) {
      return errorKeys.FILE_NOT_SELECTED
    } else if (file.size > sizeLimit) {
      return errorKeys.FILE_SIZE_EXCEED_LIMIT
    } else if (!types.includes(file.type)) {
      return errorKeys.FILE_TYPE_NOT_ACCEPTED
    }
    return ''
  }

  return validator
}
