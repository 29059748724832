<template>
  <div class="i" :class="inputClasses">
    <div class="checkbox__wrapper">
      <input :id="`i-${name}`" v-model="inputValue" class="i__check" tabindex="0" :name="name" :type="type" />
    </div>
    <label class="i__label" :for="`i-${name}`">
      <slot name="label" />
    </label>
  </div>
</template>

<script>
import InputMixin from '../../mixins/input'

export default {
  data() {
    return {
      // Input type must be "checkbox"
      type: 'checkbox',
    }
  },
  mixins: [InputMixin],
  props: {
    /**
     * Default input value
     */
    value: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style lang="postcss" scoped>
.i.i--checkbox {
  @apply flex items-center relative pl-4;
  min-height: 36px;
}
.i__check {
  &:after {
    @apply absolute inset-0 rounded-md bg-grey;
    @apply opacity-0 z-1 pointer-events-none;
    content: '';
    transition: opacity 0.2s ease;
  }
}
.i__label {
  @apply mb-0 py-1 px-4;
  line-height: 18px;
}
.checkbox__wrapper {
  @apply flex items-center;
}
@screen desktop {
  .i__check:hover:after {
    @apply opacity-10;
  }
  .i__label {
    @apply text-sm;
  }
}
</style>
