<template>
  <popup v-show="isShow" show-masthead>
    <div slot="main" class="form__main">
      <div class="form__hdr">
        <h1 class="form__title">{{ $t('login.title') }}</h1>
      </div>
      <div class="form__inputs">
        <!-- Phone number field -->
        <i-text
          v-model="fields.tel.value"
          name="tel"
          :placeholder="$t('input.telephone')"
          type="tel"
          :error="getFieldError('tel')"
          :title="$t('input.telephone')"
          :options="countryCodeList"
          @input="onFieldInput('tel')"
        />

        <!-- Cloudflare Turnstile -->
        <turnstile
          v-if="isTurnstileEnabled"
          :site-key="turnstileSitekey"
          :language="currentLocale.code"
          @success="onTurnstileSuccess"
        />
      </div>
      <!-- Error message -->
      <p v-if="formError" class="form__msg">* {{ $t(`error['${formError}']`) }}</p>
    </div>
    <div slot="actions" class="form__actions">
      <div v-if="isEnableDebug" class="form__action--consent">
        <!-- Debug checkbox -->
        <i-check v-model="debug" name="debug">
          <p slot="label">Debug mode</p>
        </i-check>
      </div>
      <div class="form__action--consent">
        <!-- Consent checkbox -->
        <i-check v-model="fields.consent.value" name="consent" @input="onFieldInput('consent')">
          <template slot="label">
            <template>{{ $t('signup.consentAgree') }} </template>
            <n-link :to="localePathName('/terms-and-conditions')" :title="tncTitle">{{ tncTitle }}</n-link>
            <template> {{ $t('signup.consentAnd') }} </template>
            <n-link :to="localePathName('/privacy-policy')" :title="privacyPolicyTitle">{{
              privacyPolicyTitle
            }}</n-link>
          </template>
        </i-check>
      </div>
      <!-- Submit button -->
      <b-main
        class="b--full"
        type="submit"
        :active="formValid && (!isTurnstileEnabled || !!turnstileToken)"
        :is-loading="formLoading"
        :title="$t('login.actionMain')"
        @click="loginMfa"
      />
    </div>
  </popup>
</template>

<script>
import { isChecked, isTextTelephoneNo } from '../../libs/validator'
import { isMfaChallengeExists, requestLoginMfa } from '../../api/octo/auth'
import AuthMixin from '../../mixins/auth'
import CheckboxInput from '../input/checkboxInput'
import FormMixin from '../../mixins/form'
import MainButton from '../button/mainButton'
import Popup from '../popup/popup'
import RedirectMixin from '../../mixins/redirect'
import TextInput from '../input/textInput'
import Turnstile from '../input/turnstile.vue'

export default {
  components: {
    'b-main': MainButton,
    'i-check': CheckboxInput,
    'i-text': TextInput,
    popup: Popup,
    turnstile: Turnstile,
  },
  computed: {
    channel() {
      return this.debug ? 'slack' : 'sms'
    },
    countryCodeList() {
      return ['+852']
    },
    isEnableDebug() {
      return process.env.SEEKER_WEB__ENVIRONMENT !== 'production' && process.env.SEEKER_WEB__ENVIRONMENT !== 'beta'
    },
    privacyPolicyTitle() {
      return this.$t('link.privacyPolicy')
    },
    tncTitle() {
      return this.$t('link.tnc')
    },
  },
  created() {
    this.$tokenReady(this.handleRedirectWhenLoggined)
  },
  data() {
    return {
      debug: false,
      fields: {
        consent: {
          validator: isChecked,
          value: false,
        },
        tel: {
          validator: isTextTelephoneNo,
          value: '',
        },
      },
      isShow: false,
      isTurnstileEnabled: false,
      turnstileSitekey: process.env.SEEKER_WEB__TURNSTILE_SITEKEY,
      turnstileToken: '',
    }
  },
  head() {
    return {
      title: this.isShow ? this.$t('login.title') : '',
    }
  },
  methods: {
    async checkMfaChallenge() {
      // only enable Cloudflare Turnstile if MFA challenge exists
      if (this.turnstileSitekey && await isMfaChallengeExists()) {
        this.isTurnstileEnabled = true
      }
    },
    handleRedirectWhenLoggined() {
      if (this.isLoggedIn) {
        this.redirect()
      } else {
        this.isShow = true
        this.checkMfaChallenge()
      }
    },
    /**
     * Submit login-mfa request to Octo *REST* endpoint,
     * redirect to login-mfa page if success
     */
    async loginMfa() {
      // Send login-mfa request to Octo
      const res = await this.submitForm(this.requestMfaFunc)
      if (res) {
        if (res.data && res.data.message === 'Accepted') {
          // Redirect to login mfa
          this.$router.push({
            path: this.localePathName('/login-mfa'),
            query: {
              ...this.redirectQuery,
              channel: this.channel,
              code: '852',
              tel: this.fields.tel.value,
            },
          })
        } else if (res.error) {
          // Otherwise handle login error
          console.error(`Request error: ${res.error.status}`) // eslint-disable-line no-console
        }
      }
    },
    onTurnstileSuccess(token) {
      this.turnstileToken = token
    },
    requestMfaFunc() {
      return requestLoginMfa(this.currentLocale.code, this.fields.tel.value, this.channel, 'web', this.token)
    },
  },
  mixins: [AuthMixin, FormMixin, RedirectMixin],
}
</script>

<style lang="postcss" scoped>
.form__main {
  @apply w-full overflow-y-auto;
}
>>> .popup-hdr {
  @apply mt-4;
}

.form__title {
  font-size: 22px;
}

.form__inputs >>> .i.i--error .i__input {
  box-shadow: none;
}

.form__inputs >>> .i__container {
  @apply mb-2;
}

.form__inputs >>> .i__dropdwon {
  @apply border border-solid border-grey-300 text-grey-600;
}
@screen desktop {
  .popup.form {
    width: 400px;
  }
  .form__inputs >>> .i__label {
    margin-bottom: 0.5rem;
  }
}
</style>
